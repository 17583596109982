import React, { useEffect } from 'react';
import './App.css';
import ContactForm from './components/ContactForm';

function App() {
  useEffect(() => {
    document.title = "Holley Family Solutions | Enterprise IT Services";
  }, []);

  return (
    <div>
      <nav className="nav">
        <div className="nav-content">
          <a 
            href="https://www.holleyfamilysolutions.com"
            className="nav-brand" 
            onClick={(e) => { 
              e.preventDefault();
              window.scrollTo({ 
                top: 0, 
                behavior: 'smooth'
              }); 
            }}
            style={{ 
              textDecoration: 'none', 
              color: 'white',
              cursor: 'pointer' 
            }}
          >
            Holley Family Solutions
          </a>
          
          <div className="nav-links">
            <a href="#about">About</a>
            <a href="#services">Services</a>
            <a href="#expertise">Expertise</a>
            <a href="#contact">Contact</a>
          </div>
        </div>
      </nav>

      <div className="hero">
        <h1>Professional IT Solutions for Growing Businesses</h1>
        <p className="hero-subtitle">Where Certified Expertise Meets Personalized IT Support in Syracuse, NY</p>
        <div className="certifications">
          <a
            href="https://www.credly.com/badges/6a4d3fa2-db6a-4e13-84a9-c65f4e9a7558/public_url"
            target="_blank"
            rel="noopener noreferrer"
            className="cert-badge"
            aria-label="CompTIA Network+ Certification Badge"
          >
            CompTIA Network+ Certified
            
          </a>
          <a
            href="https://www.credly.com/badges/77121432-e2d7-40b8-bf60-6cea2532d9ee/public_url"
            target="_blank"
            rel="noopener noreferrer"
            className="cert-badge"
            aria-label="CompTIA Security+ Certification Badge"
          >
            CompTIA Security+ Certified
            
          </a>
        </div>
      </div>

      <section id="about" className="section">
        <h2>About</h2>
        <div className="about-content">
          <p className="lead-text">Holley Family Solutions is founded and led by a certified IT professional who understands that small and medium-sized businesses deserve enterprise-grade technology solutions without the enterprise-level complexity. Through proven expertise in network security, infrastructure management, and dedicated technical support, we deliver professional IT services that help your business thrive in today's digital landscape.</p>
          <div className="experience-highlights">
            <div className="highlight-card">
              <h4>Infrastructure Management</h4>
              <p>From network optimization to server management, we build and maintain the robust IT foundation your business needs to operate efficiently and securely.</p>
            </div>
            <div className="highlight-card">
              <h4>Security Focus</h4>
              <p>As a CompTIA Security+ certified professional, we implement proven security measures that protect your business assets while keeping your systems accessible and productive.</p>
            </div>
            <div className="highlight-card">
              <h4>Hardware Specialist</h4>
              <p>Expert configuration and maintenance of your critical hardware infrastructure, from specialized printing systems to custom workstations designed for your unique needs.</p>
            </div>
            <div className="highlight-card">
              <h4>Customer Support Expert</h4>
              <p>Responsive, clear, and solution-focused support that treats your team with respect. We explain technical concepts in plain language and ensure your staff feels confident using their technology.</p>
            </div>
          </div>
        </div>
      </section>

      <section id="services" className="section">
        <h2>Services</h2>
        <div className="services-grid">
          <div className="service-card">
            <h3>Network Security & Infrastructure</h3>
            <ul className="service-list">
              <li>Strategic UniFi equipment deployment and management</li>
              <li>Advanced SonicWall firewall configuration</li>
              <li>Custom access control systems that balance security with usability</li>
              <li>Continuous network monitoring and threat prevention</li>
            </ul>
          </div>
          <div className="service-card">
            <h3>Server & System Administration</h3>
            <ul className="service-list">
              <li>Expert Windows Server environment optimization</li>
              <li>Streamlined Microsoft 365 deployment and administration</li>
              <li>Strategic infrastructure planning and implementation</li>
              <li>Proactive system maintenance and security updates</li>
            </ul>
          </div>
          <div className="service-card">
            <h3>Specialized Technical Support</h3>
            <ul className="service-list">
              <li>Custom hardware solutions tailored to your workflow</li>
              <li>Professional thermal printer configuration and maintenance</li>
              <li>Performance-optimized workstation building and setup</li>
              <li>Comprehensive IT asset lifecycle management</li>
            </ul>
          </div>
        </div>
      </section>

      <section id="expertise" className="section expertise-section">
  <h2>Technical Expertise</h2>
  <div className="expertise-grid">
    <div className="expertise-card">
      <h3>Systems & Infrastructure</h3>
      <ul className="expertise-list">
        <li>Windows Server Management: Expert configuration and maintenance of your server environment to keep your business running smoothly</li>
        <li>Microsoft 365 Administration: Comprehensive setup and management of your business communication and productivity suite</li>
        <li>UniFi Network Solutions: Strategic deployment and optimization of professional-grade networking equipment</li>
        <li>SonicWall Security: Advanced firewall configuration and monitoring to protect your business assets</li>
        <li>Network Architecture: Custom-designed network solutions that scale with your business needs</li>
        <li>System Performance: Proactive monitoring and optimization to prevent issues before they impact your business</li>
      </ul>
    </div>
    <div className="expertise-card">
      <h3>Security & Support</h3>
      <ul className="expertise-list">
        <li>Network Security: Multi-layered protection strategies that safeguard your business data</li>
        <li>Access Control: Custom-configured systems that balance security with ease of use</li>
        <li>Technical Support: Clear, patient assistance that helps your team work confidently with technology</li>
        <li>Asset Management: Strategic oversight of your IT resources to maximize value and performance</li>
        <li>Hardware Solutions: Expert configuration of workstations, printers, and specialized equipment</li>
        <li>Thermal Printing: Specialized knowledge in setting up and maintaining thermal printing systems</li>
      </ul>
    </div>
  </div>
</section>

      <section id="contact" className="section">
        <h2>Contact</h2>
        <div className="contact-container">
          <div className="contact-info">
            <h3>Ready to Strengthen Your IT Infrastructure?</h3>
            <p>Experience the difference that professional, attentive IT support can make for your business. Based in Syracuse, we provide responsive solutions that keep your technology running at its best.</p>
            <div className="contact-highlights">
              <div className="contact-highlight">
                <strong>Location:</strong> Proudly serving businesses throughout the Syracuse area
              </div>
              <div className="contact-highlight">
                <strong>Response Time:</strong> Committed to addressing your needs within 24 hours
              </div>
              <div className="contact-highlight">
                <strong>Service Approach:</strong> Personal attention meets professional expertise
              </div>
            </div>
          </div>
          <ContactForm />
        </div>
      </section>

      <footer className="footer">
        <div className="footer-content">
          <div>© 2024 Holley Family Solutions</div>
          <div className="footer-links">
            <a href="https://www.linkedin.com/in/brianshamier" target="_blank" rel="noopener noreferrer">LinkedIn</a>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;