import React, { useState } from 'react';

function ContactForm() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    company: '',
    message: ''
  });

  // Function to sanitize input by removing potentially harmful characters
  const sanitizeInput = (input) => {
    // Remove HTML tags and special characters
    return input.replace(/[<>]/g, '').trim();
  };

  // Function to validate email format
  const isValidEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    // Sanitize input as it's entered
    setFormData(prev => ({
      ...prev,
      [name]: sanitizeInput(value)
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Validate all inputs before submission
    if (!formData.name || !formData.email || !formData.message) {
      alert('Please fill in all required fields');
      return;
    }

    if (!isValidEmail(formData.email)) {
      alert('Please enter a valid email address');
      return;
    }

    // Additional security checks
    const maxLength = 1000; // Adjust this value based on your needs
    if (formData.message.length > maxLength) {
      alert('Message is too long');
      return;
    }

    // If all validation passes, proceed with form submission
    try {
      const response = await fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: new URLSearchParams({
          'form-name': 'contact',
          ...formData
        }).toString()
      });
      
      if (response.ok) {
        // Clear form after successful submission
        setFormData({
          name: '',
          email: '',
          company: '',
          message: ''
        });
        alert('Thank you for your message!');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      alert('There was an error submitting the form. Please try again.');
    }
  };

  return (
    <form 
      name="contact" 
      method="POST" 
      data-netlify="true" 
      className="contact-form"
      onSubmit={handleSubmit}
    >
      <input type="hidden" name="form-name" value="contact" />
      
      <div className="form-group">
        <label htmlFor="name">Name *</label>
        <input 
          type="text" 
          id="name" 
          name="name" 
          value={formData.name}
          onChange={handleChange}
          maxLength={100}
          required 
        />
      </div>
      
      <div className="form-group">
        <label htmlFor="email">Email *</label>
        <input 
          type="email" 
          id="email" 
          name="email" 
          value={formData.email}
          onChange={handleChange}
          maxLength={100}
          required 
        />
      </div>

      <div className="form-group">
        <label htmlFor="company">Company</label>
        <input 
          type="text" 
          id="company" 
          name="company" 
          value={formData.company}
          onChange={handleChange}
          maxLength={100}
        />
      </div>
      
      <div className="form-group">
        <label htmlFor="message">How Can I Help? *</label>
        <textarea 
          id="message" 
          name="message" 
          value={formData.message}
          onChange={handleChange}
          rows="4" 
          maxLength={1000}
          required
        />
      </div>
      
      <button type="submit" className="button">Send Message</button>
    </form>
  );
}

export default ContactForm;